<template>
  <div id="adminSubject">
    <a-modal
      v-model="visibleModalManageSubject"
      class="modal-subject"
      :title="status.isEdit ? 'Form Edit Subject' : 'Form Create Subject'"
      @cancel="toggleModalManageSubject"
      centered
    >
      <div>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item required label="Name">
              <a-input
                size="large"
                class="w-100"
                placeholder="Input Subject Name"
                :value="newSubject.nama"
                @change="(e) => handleChange(e.target.value, 'nama')"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item required label="Code">
              <a-input
                size="large"
                class="w-100"
                placeholder="Input Subject Code"
                :value="newSubject.code"
                @change="(e) => handleChange(e.target.value, 'code')"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item required label="English Name">
              <a-input
                size="large"
                class="w-100"
                placeholder="Input Subject English Name"
                :value="newSubject.englishName"
                @change="(e) => handleChange(e.target.value, 'englishName')"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item
                label="Subject Report"
              >
              <!-- <span slot="label"> Set to Mi&nbsp;</span> -->
              <div class="p-3 border rounded">
                <a-checkbox
                  :checked="newSubject.isReport"
                  @change="(e) => handleChange(e.target.checked, 'isReport')"
                >
                  Set as report's subject
                </a-checkbox>
              </div>
            </a-form-model-item>
          </a-col>
          <a-col :span="24" v-if="this.newSubject.isReport">
            <a-form-item required label="Report Order">
              <a-input-number
                size="large"
                :min="1"
                :value="newSubject.reportOrder"
                @change="(e) => handleChange(e, 'reportOrder')"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Group">
              <a-select
                size="large"
                class="w-100"
                @change="e => handleSelect(e, 'group')"
                :value="newSubject.group"
              >
                <a-select-option value="">No Group</a-select-option>
                <a-select-option
                  v-for="item in listGroup"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.nama }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Semester">
              <a-select
                size="large"
                class="w-100"
                @change="(e) => handleSelect(e, 'semester')"
                :value="newSubject.semester"
              >
                <a-select-option value="">All Semester</a-select-option>
                <a-select-option value="Ganjil">First</a-select-option>
                <a-select-option value="Genap">Second</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Level">
              <a-select
                size="large"
                class="w-100"
                @change="e => handleSelect(e, 'id_level')"
                :value="newSubject.id_level"
              >
                <a-select-option value="">All Level</a-select-option>
                <a-select-option
                  v-for="item in listLevel"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.nama }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Program">
              <a-select
                size="large"
                class="w-100"
                @change="e => handleSelect(e, 'program')"
                :value="newSubject.program"
              >
                <a-select-option value="">All Program</a-select-option>
                <a-select-option
                  v-for="item in listProgram"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.nama }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="Generic Name">
              <a-select
                size="large"
                class="w-100"
                @change="e => handleSelect(e, 'kategori_mapel')"
                :value="newSubject.kategori_mapel"
              >
                <a-select-option value="">All Generic Name</a-select-option>
                <a-select-option
                  v-for="item in listKategoriMapel"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.generic_name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="Is Counseling Guidance Subject">
              <a-switch v-model="newSubject.isBK" size="large" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item
              label="Cover Image"
              enctype="multipart/form-data"
            >
              <a-upload
                name="cover"
                class="cover-uploader w-100"
                accept="image/jpg,image/jpeg,image/png"
                list-type="picture-card"
                :show-upload-list="false"
                :multiple="false"
                :value="newSubject.cover"
                :before-upload="beforeUpload"
                @change="handleChangeImage"
              >
                <div v-if="newSubject.cover">
                  <img
                    :src="previewImage || newSubject.cover"
                    alt="cover"
                    width="100%"
                    height="100%"
                  />
                </div>
                <div v-else>
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">Upload</div>
                </div>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>
      </div>
      <template slot="footer">
        <a-button size="large" key="back" @click="toggleModalManageSubject">
          Cancel
        </a-button>
        <a-button
          size="large"
          key="submit"
          type="primary"
          :loading="loadingActionModalManageSubject"
          @click="handleOkModalManageSubject"
        >
          {{ status.isEdit ? "Edit" : "Create" }} Now
        </a-button>
      </template>
    </a-modal>
    <div class="d-flex align-items center">
      <a-input-group compact>
        <a-select @change="changeFilterBy" style="min-width: 125px" size="large" v-model="filter.by">
          <a-select-option value="">All Data</a-select-option>
          <a-select-option value="id_kelompok">Group</a-select-option>
          <a-select-option value="id_program">Program</a-select-option>
          <a-select-option value="id_level">Level</a-select-option>
          <a-select-option value="semester">Semester</a-select-option>
          <a-select-option value="id_kategori_mapel">Generic Name</a-select-option>
        </a-select>
        <a-select @change="fetchDataSubject" style="min-width: 200px" size="large" v-if="filter.by !== ''" v-model="filter.value">
          <a-select-option v-for="val in filtersData" :key="val.data" :value="val.data">{{val.name}}</a-select-option>
        </a-select>
      </a-input-group>
      <!-- <a-select
        size="large"
        style="min-width: 150px"
        @change="fetchDataSubject"
        v-model="filter.id_group"
      >
        <a-select-option value="all">All Group</a-select-option>
        <a-select-option
          v-for="item in listGroup"
          :key="item.id"
          :value="item.id"
          >{{ item.nama }}</a-select-option
        >
      </a-select> -->
      <div class="ml-auto">
        <a-button
          @click.prevent="toggleModalManageSubject('add')"
          size="large"
          type="primary"
          ><a-icon type="plus" /> Create Subject
        </a-button>
      </div>
    </div>
    <div class="mt-4">
      <a-table
        :columns="subjectColumns"
        :data-source="subjectDataTable"
        :pagination="pagination"
        :loading="subjectLoadingTable"
        :scroll="{ x: 1000 }"
        @change="handleTableChange"
        bordered
      >
        <template slot="cover" slot-scope="value, text">
          <div v-if="value !== null">
            <img height="100%" width="100%" :src="value" :alt="text" />
          </div>
          <div v-else>
            {{ "-" }}
          </div>
        </template>
        <span slot="nullStr" slot-scope="val">{{val || '-'}}</span>
        <div slot="action" slot-scope="value, record">
          <a-button
            @click.prevent="toggleModalManageSubject('edit', record)"
            block
            class="m-2 text-warning border border-warning"
            size="large"
          >
            <a-icon type="edit" /> Edit
          </a-button>
          <a-button
            @click.prevent="handleDeleteSubject(record)"
            block
            class="text-danger border border-danger m-2 ml-0"
            size="large"
          >
            <a-icon type="delete" /> Delete
          </a-button>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import axios from 'axios'

const subjectColumns = [
  {
    title: 'Name',
    dataIndex: 'nama',
    key: 'nama',
    align: 'center',
    width: 120,
    fixed: 'left',
    scopedSlots: { customRender: 'nama' },
  },
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
    align: 'center',
    width: 80,
    scopedSlots: { customRender: 'code' },
  },
  {
    title: 'English Name',
    dataIndex: 'englishName',
    key: 'englishName',
    align: 'center',
    width: 120,
    ellipsis: true,
    scopedSlots: { customRender: 'nullStr' },
  },
  {
    title: 'Report Order',
    dataIndex: 'reportOrder',
    key: 'reportOrder',
    align: 'center',
    width: 100,
    scopedSlots: { customRender: 'nullStr' },
  },
  {
    title: 'Semester',
    dataIndex: 'semester',
    key: 'semester',
    align: 'center',
    width: 120,
    scopedSlots: { customRender: 'nullStr' },
  },
  {
    title: 'Level',
    dataIndex: 'levelName',
    key: 'level',
    align: 'center',
    width: 100,
    scopedSlots: { customRender: 'nullStr' },
  },
  {
    title: 'Program',
    dataIndex: 'programName',
    key: 'program',
    align: 'center',
    width: 100,
    scopedSlots: { customRender: 'nullStr' },
  },
  {
    title: 'Group',
    dataIndex: 'groupName',
    key: 'group',
    align: 'center',
    width: 100,
    scopedSlots: { customRender: 'nullStr' },
  },
  {
    title: 'Generic Name',
    dataIndex: 'genericName',
    key: 'generic',
    align: 'center',
    width: 100,
    scopedSlots: { customRender: 'nullStr' },
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    align: 'center',
    fixed: 'right',
    width: 150,
    scopedSlots: { customRender: 'action' },
  },
]
export default {
  name: 'Subject',
  data() {
    return {
      subjectColumns,
      visibleModalManageSubject: false,
      loadingActionModalManageSubject: false,
      loadingDeleteSubject: false,
      subjectLoadingTable: false,
      subjectDataTable: [],
      status: {
        isEdit: false,
        isCreate: false,
      },
      newSubject: {
        nama: null,
        code: null,
        englishName: null,
        reportOrder: null,
        cover: null,
        program: '',
        group: '',
        isReport: true,
        id_level: '',
        semester: '',
        kategori_mapel: '',
        isBK: false,
      },
      idEdit: null,
      config,
      loading: false,
      previewImage: null,
      pagination: { current: 1 },
      order: 'ASC',
      listProgram: [],
      listGroup: [],
      listLevel: [],
      listKategoriMapel: [],
      filter: {
        by: '',
        value: null,
      },
      listSemester: [
        {
          data: 'Ganjil',
          name: 'Ganjil',
        }, {
          data: 'Genap',
          name: 'Genap',
        },
      ],
    }
  },
  methods: {
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    async handleChangeImage(value) {
      // console.log('handleChangeImage value => ', value)
      const fileList = value.fileList
      const idxOfNewFile = fileList.length - 1
      const file = fileList[idxOfNewFile]
      // console.log('this.newSubject.cover', this.newSubject.cover)
      const imageType = file.type
      if (imageType === 'image/jpg' || imageType === 'image/jpeg' || imageType === 'image/png') {
        if (file) {
          if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj)
          }
          this.previewImage = file.url || file.preview
          try {
            return this.$notification.success({
              message: 'Success Upload Image.',
            })
          } catch (error) {
            console.log(error)
            return this.$notification.error({
              message: 'Error!',
              description: error,
            })
          }
        } else {
          this.newSubject.cover = null
          return this.$notification.warning({
            message: 'Success Remove Image',
            description: 'Please Upload New Image',
          })
        }
      } else {
        this.newSubject.cover = null
        return this.$notification.error({
          message: 'Fail Upload Image!',
          description: `This Type of Image: ${imageType} is not permitted! Supported Image Type: JPG / JPEG / PNG.`,
        })
      }
    },
    beforeUpload(file) {
      this.newSubject.cover = file
      return false
    },
    handleChange(value, column) {
      if (column === 'isReport') {
        if (!value) this.newSubject.reportOrder = null
      }
      // console.log('handleChange => ', value, column)
      this.newSubject[column] = value
    },
    toggleModalManageSubject(status, data) {
      this.visibleModalManageSubject = !this.visibleModalManageSubject
      if (!this.visibleModalManageSubject) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
          }
          this.newSubject = {
            nama: null,
            code: null,
            englishName: null,
            reportOrder: null,
            cover: null,
            program: '',
            group: '',
            id_level: '',
            isReport: true,
            semester: '',
            kategori_mapel: '',
            isBK: false,
          }
          this.previewImage = null
          this.idEdit = null
        }, 500)
        return ''
      }

      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          this.idEdit = data.key
          this.newSubject = {
            nama: data.nama,
            code: data.code,
            englishName: data.englishName,
            reportOrder: data.reportOrder,
            cover: data.cover,
            program: data.program || '',
            group: data.group || '',
            id_level: data.id_level || '',
            isReport: Boolean(data.isReport),
            semester: data.semester || '',
            kategori_mapel: data.kategori_mapel || '',
            isBK: data.isBK,
          }
        }
      }
    },
    async handleOkModalManageSubject() {
      const content = `${this.status.isEdit ? 'Are you sure want to edit this study subject?' : 'Are you sure want to create new study subject'}`
      if (this.isValidateForm) {
        this.$notification.error({
          message: 'Warning',
          description: 'All fields are required',
        })
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>{content}</div>
          ),
          onOk: async() => {
            this.loadingActionModalManageSubject = true
            const formData = new FormData()
            const id = this.idEdit
            const nama = this.newSubject.nama
            const code = this.newSubject.code
            const englishName = this.newSubject.englishName
            const reportOrder = this.newSubject.reportOrder
            const cover = this.newSubject.cover
            const program = this.newSubject.program || null
            const group = this.newSubject.group || null
            const idLevel = this.newSubject.id_level || null
            const isReport = this.newSubject.isReport
            const semester = this.newSubject.semester || ''
            const generic = this.newSubject.kategori_mapel || null
            formData.append('id', id)
            formData.append('nama', nama)
            formData.append('code', code)
            formData.append('englishName', englishName)
            formData.append('reportOrder', reportOrder)
            formData.append('cover', cover)
            formData.append('program', program)
            formData.append('group', group)
            formData.append('isReport', isReport)
            formData.append('id_level', idLevel)
            formData.append('semester', semester)
            formData.append('generic', generic)
            formData.append('isBK', this.newSubject.isBK)
            try {
              if (this.status.isEdit) {
                const updated = await axios.put(`${config.apiUrl}/api/mata_pelajaran/admin/update/${id}`, formData, {
                  headers: {
                    token: localStorage.token,
                  },
                })
                this.loadingActionModalManageSubject = false
                this.toggleModalManageSubject()
                this.fetchDataSubject()
                if (updated.status === 200) {
                  this.$notification.success({
                    message: 'Success',
                    description:
                      'Study Subject has been edited',
                  })
                }
              } else {
                const created = await axios.post(`${config.apiUrl}/api/mata_pelajaran/admin/create`, formData, {
                  headers: {
                    token: localStorage.token,
                  },
                })
                this.loadingActionModalManageSubject = false
                this.toggleModalManageSubject()
                this.fetchDataSubject()
                if (created.status === 200) {
                  this.$notification.success({
                    message: 'Success',
                    description:
                      'Study Subject has been created',
                  })
                }
              }
            } catch (error) {
              this.loadingActionModalManageSubject = false
              this.toggleModalManageSubject()
              console.log(error)
              this.$notification.error({
                message: 'ERROR',
                description: error,
              })
            }
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.status.isEdit ? 'Edit Now' : 'Create Now',
          cancelText: 'Cancel',
        })
      }
    },
    handleDeleteSubject(data) {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure want to delete this study subject?</div>
        ),
        onOk: () => {
          this.subjectLoadingTable = true
          this.loadingDeleteSubject = true
          this.$store.dispatch('master/DELETE_SUBJECT', {
            id: data.key,
          })
            .then(isSuccess => {
              this.fetchDataSubject()
              this.loadingDeleteSubject = false
              if (isSuccess) {
                this.$notification.success({
                  message: 'Success',
                  description:
                    'Study Subject has been deleted',
                })
              } else {
                this.$notification.error({
                  message: 'Failed',
                  description: 'Study Subject has not been deleted',
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDeleteSubject = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
        cancelText: 'Cancel',
      })
    },
    async fetchDataSubject() {
      try {
        this.subjectLoadingTable = true
        const filter = this.filter.by !== '' ? {
          filterBy: this.filter.by,
          filterValue: this.filter.value,
        } : {}
        const res = await this.$store.dispatch('master/FETCH_SUBJECT', { page: this.pagination.current, order: this.order, sortBy: this.sortBy, ...filter })
        // console.log('fetchDataSubject res => ', res)
        this.subjectLoadingTable = false
        const pagination = { ...this.pagination }
        pagination.total = res.total
        this.pagination = pagination
        this.subjectDataTable = res.dataTable.map(el => {
          return {
            key: el.id,
            nama: el.nama,
            code: el.code,
            englishName: el.english_name,
            reportOrder: el.report_order,
            cover: el.cover ? `${this.config.apiUrl}${el.cover}` : null,
            program: el.id_program ? el.id_program : null,
            programName: el.program ? el.program.nama : null,
            id_level: el.id_level,
            levelName: el.level?.nama,
            group: el.id_kelompok ? el.id_kelompok : null,
            groupName: el.kelompok ? el.kelompok.nama : null,
            isReport: el.isReport,
            semester: el.semester,
            generic: el.id_kategori_mapel ? el.id_kategori_mapel : null,
            genericName: el.kategori_mapel ? el.kategori_mapel.generic_name : null,
            isBK: el.isBK,
          }
        })
        this.listProgram = res.listProgram
        this.listGroup = res.listKelompok
        this.listKategoriMapel = res.listKategoriMapel
      } catch (err) {
        this.subjectLoadingTable = true
        console.log(err)
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      if (sorter.order === 'ascend') {
        this.order = 'ASC'
      } else if (sorter.order === 'descend') {
        this.order = 'DESC'
      } else {
        this.order = 'ASC'
      }
      this.fetchDataSubject()
    },
    handleSelect(value, column) {
      // console.log(value, column)
      if (column === 'program') {
        this.newSubject.program = value
      } else if (column === 'group') {
        this.newSubject.group = value
      } else if (column === 'id_level') {
        this.newSubject.id_level = value
      } else if (column === 'kategori_mapel') {
        this.newSubject.kategori_mapel = value
      } else {
        this.newSubject[column] = value
      }
    },
    async fetchLevels() {
      try {
        const data = await this.$store.dispatch('master/FETCH_LEVEL', { page: 'all' })
        this.listLevel = data.map(level => {
          return {
            nama: level.nama,
            id: level.id,
          }
        })
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    changeFilterBy() {
      if (this.filter.by === '') this.fetchDataSubject()
      this.filter.value = null
    },
  },
  mounted() {
    this.fetchLevels()
    this.fetchDataSubject()
  },
  computed: {
    isValidateForm() {
      const { nama, code, isReport, reportOrder, englishName } = this.newSubject
      if (nama && code && englishName && ((isReport && reportOrder) || !isReport)) {
        return false
      }
      return true
    },
    filtersData() {
      let datas = []
      if (this.filter.by === 'semester') {
        datas = this.listSemester
      } else if (this.filter.by === 'id_level') {
        datas = this.listLevel.map(level => {
          return {
            data: level.id,
            name: level.nama,
          }
        })
      } else if (this.filter.by === 'id_kelompok') {
        datas = this.listGroup.map(group => {
          return {
            data: group.id,
            name: group.nama,
          }
        })
      } else if (this.filter.by === 'id_program') {
        datas = this.listProgram.map(program => {
          return {
            data: program.id,
            name: program.nama,
          }
        })
      } else if (this.filter.by === 'id_kategori_mapel') {
        datas = this.listKategoriMapel.map(kategoriMapel => {
          return {
            data: kategoriMapel.id,
            name: kategoriMapel.generic_name,
          }
        })
      }
      return datas
    },
  },
}
</script>

<style lang="scss">
#adminSubject {
  }
.modal-subject {
  .cover-uploader {
    .ant-upload-select-picture-card {
      width: 100%;
    }
  }
}
</style>
